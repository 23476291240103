$(document).ready(function() {
    if(!document.querySelector('.GPC0162')) return false;
    
    var $tmpl = $('#pdpFloatingIconTemplate');
    $tmpl.length && $($tmpl[0].innerHTML).find('.btn-whatsapp').click(function() {
        var $this = $(this);
        var btnLink = $this.data('float-btn-link');
        btnLink && openWin(btnLink, '', $this.data('width') , $this.data('height'));
    }).end().appendTo('body').show();
    
    if(isMobile && $('.GPC0076-whatsapp').length > 0 && COUNTRY_CODE.toLowerCase() != 'br'){
        $('.GPC0076-whatsapp').remove();
    }
    
    var $item_width = $('.list-box').find('li > .item').outerWidth();
    
    if ($(window).width() < 767 || isMobile){
        $('.pd-share').find('.list').addClass('mobileSns');
    } else{
        $('.pd-share').find('.list').css('width', $item_width);
        $('.pd-share').find('.list').removeClass('mobileSns');
    }
    
    if($('.share-common').length > 0){
        initShareCommon();
    }
    
    var GPC0162 = {
        localeCode          : $("html").data("countrycode")
        , accessToken       : ""
        , ajaxUrl           : ""
        //PJTPROMOAF-1 Start
        , obsPincodeFlag    : ""
        , checkSaleFlag     : ""
        , pincodeBtnFlag    : ""
        //PJTPROMOAF-1 End
        , productTemplate   : $("#productTemplate", ".GPC0162").clone().html()
        , pageTemplate      : $('#paginationTemplate', ".GPC0162").clone().html()
        , afterItemTemplate : $('#afterItemTemplate', ".GPC0162").clone().html()
        //LGEITF-925 Start
        , tabInitFlag       : "Y"
        //LGEITF-925 End
        , init : function() {
            $("#productTemplate", ".GPC0162").remove();
            $("#paginationTemplate", ".GPC0162").remove();
            $("#afterItemTemplate", ".GPC0162").remove();
            
            GPC0162.setVal();
            GPC0162.getProductList();
            this.setBtnEvent();
            GPC0162.calculatePrice();
            GPC0162.mCustom_init();
        }
        , setVal : function() {
            GPC0162.ajaxUrl     = "/" + GPC0162.localeCode + "/mkt/ajax/packageDeal/retrievePackageDealCategoryProductListAjax";
            GPC0162.accessToken = sessionStorage.getItem("ACCESS_TOKEN");
            
            //PJTPROMOAF-1 Start
            GPC0162.obsPincodeFlag  = $("input[name='obsPincodeFlag']", ".GPC0162").val();
            GPC0162.pincodeBtnFlag  = "N";
            //PJTPROMOAF-1 End
        }
        , getProductList : function() {
            var form        = $("#categoryForm");
            var isExpander  = $(form).data('focus') == true;
            var param       = xssfilter($(form).serialize());
            var initMobile  = false;
            initMobile      = isMobile;
            
            if(!!initMobile){
                param = param + '&initMobile=Y';
            }
            
            //PJTPROMOAF-1 Start
            if (GPC0162.obsPincodeFlag == "Y" && GPC0162.localeCode != "cl") {
                if (GPC0162.pincodeBtnFlag  == "Y") {
                    param = param + "&pincode=" + $("#pincode", ".GPC0162").val();
                }
            }
            //PJTPROMOAF-1 End
            
            ajax.call(GPC0162.ajaxUrl, param, 'json', function(d){
                var data;
                
                if (d && d.data) {
                    data = d.data instanceof Array ? d.data[0] : d.data;
                }
                
                if(d.status == "success" && data) {
                    
                    console.log(data);
                    
                    //PJTPROMOTAF-1 Start
//                    if ($("input[name='tabChangeFlag']", "#categoryForm").val() == "Y") {
//                        var viewAllMsg          = $("input[name='viewAllMsg']", "#categoryForm").val();
//                        var defaultOption       = "<option value='' selected>" + viewAllMsg + "</option>";
//                        var subCategoryList     = data.subCategoryList;
//                        
//                        $("select[name='sortBy']", ".GPC0162").empty();
//                        
//                        if (subCategoryList != null && subCategoryList.length > 0) {
//                            for (var i=0; i<subCategoryList.length; i++) {
//                                defaultOption   += "<option value='" + subCategoryList[i].subCategoryId + "'>";
//                                defaultOption   += subCategoryList[i].subCategoryName + "</option>";
//                            }
//                        }
//                        
//                        $("select[name='sortBy']", ".GPC0162").append(defaultOption);
//                        $("select[name='sortBy']", ".GPC0162").trigger("chosen:updated");
//                    }
                    
                    if (GPC0162.obsPincodeFlag == "Y" && GPC0162.localeCode != "cl") {
                        
                        if (data.checkSaleFlag != null && data.checkSaleFlag != "") {
                            var pincode         = $("#pincode", ".GPC0162").val();
                            var originPincode   = $("input[name='originPincode']", ".GPC0162").val();
                            var resultArea      = $(".error-msg", ".zip-code");
                            
                            if (pincode != null && pincode != "") {
                                if (originPincode != pincode) {
                                    
                                    GPC0162.checkSaleFlag   = data.checkSaleFlag;
                                    
                                    $("input[name='checkSaleMessage']", ".GPC0162").val(data.checkSaleMessage);
                                    $("input[name='checkSaleFlag']", ".GPC0162").val(data.checkSaleFlag);
                                    $("input[name='originPincode']", ".GPC0162").val(data.originPincode);
                                    
                                    resultArea.empty();
                                }
                                
                                if (data.checkSaleFlag == "Y") {
                                    
                                    var msgHtml = ""
                                    
                                    if (data.obsModelList != null && data.obsModelList != "") {
                                        $("input[name='obsModelList']", ".GPC0162").val(data.obsModelList);
                                        
                                        $(".btn-pincode-confirm", ".GPC0162").addClass("d-none");
                                        $(".btn-change-pincode", ".GPC0162").removeClass("d-none");
                                        $("#pincode", ".zip-code").attr("disabled", true);
                                        
                                        if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "select") {
                                            $("#pincode", ".zip-code").trigger("chosen:updated");
                                        }
                                        
                                        $(".error-msg", ".zip-code").addClass("d-block");
                                        $(".field-block", ".zip-code").removeClass("error");
                                        $("#pincode", ".zip-code").removeAttr("aria-invalid");
                                        $("#pincode", ".zip-code").removeAttr("aria-describedby");
                                        
                                        msgHtml += "<div class='check-good'>";
                                        msgHtml += "<p class='pincode-ok'>" + data.checkSaleMessage + "</p>";
                                        msgHtml += "</div>";
                                    }
                                    
                                    resultArea.html(msgHtml);
                                } else {
                                    
                                    GPC0162.pincodeBtnFlag  = "N";
                                    
                                    var errMsgHtml      = "<span class='check-fail' id='" + $("#pincode", ".zip-code").attr("name") + "Error" + "'>" + data.checkSaleMessage + "</span>";
                                    resultArea.html(errMsgHtml);
                                    
                                    $(".btn-pincode-confirm", ".GPC0162").removeClass("d-none");
                                    $(".btn-change-pincode", ".GPC0162").addClass("d-none");
                                    $("#pincode", ".zip-code").attr("disabled", false);
                                    
                                    if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "select") {
                                        $("#pincode", ".zip-code").trigger("chosen:updated");
                                    }
                                    
                                    $(".error-msg", ".zip-code").addClass("d-block");
                                    $(".field-block", ".zip-code").addClass("error");
                                    $("#pincode", ".zip-code").attr("aria-invalid", true);
                                    $("#pincode", ".zip-code").attr("aria-describedby", $("#pincode", ".zip-code").attr("name") + "Error");
                                    
                                    if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "input") {
                                        $("#pincode", ".zip-code").focus();
                                    } else {
                                        $("#pincode", ".zip-code").trigger("chosen:activate");
                                    }
                                }
                            }
                        }
                    }
                    //PJTPROMOTAF-1 End
                    
                    var categoryId  = "#" + $("input[name='categoryId']", "#categoryForm").val();
                    
                    data.productList = data.productList && data.productList != null ? data.productList : [];
                    
                    var html    = GPC0162.createProductItem(data.productList, data.productMessages, data);
                    
                    var pageInfo = data.pageInfo;
                    
                    if ($("input[name='categoryId']", "#categoryForm").val() != "") {
                        if(isExpander) { // mobile append list
                            $('.pagination', categoryId).hide();
                            
    //                        $('.list-box', categoryId).empty();
                            $('.list-box', categoryId).append(html);
                            bindImgError();
                            runBVStaticPLP($(categoryId));
                            
                            if(typeof renderListingInlineRatingsRU != 'undefined') renderListingInlineRatingsRU(getProductsNameRU());
                            
                            var pageVisible = pageInfo && pageInfo.view == "Y";
                            
                            if(pageVisible && pageInfo.page < pageInfo.pageCount) {
                                $('.expander button', categoryId).val(Number(pageInfo.page) + 1);
                                $("input[name='page']", "#categoryForm").val(Number(pageInfo.page) + 1);
                                $('.expander', categoryId).show();
                            }else {
                                $('.expander', categoryId).hide();
                            }
                        } else {
                            $('.expander', categoryId).hide();
                            $('.list-box', categoryId).empty();
                            $('.list-box', categoryId).append(html);
                            bindImgError();
                            runBVStaticPLP($(categoryId));
                            if(typeof renderListingInlineRatingsRU != 'undefined') renderListingInlineRatingsRU(getProductsNameRU());
                            
                            /*// LGEGMC-754 : 20201123 add */
                            // setting pagination
                            if(pageInfo && pageInfo.view == "Y") {
                                if($('.pagination', categoryId)){
                                    var pageMarkup = [];
                                    for (var pageIdx = pageInfo.loopStart; pageIdx <= pageInfo.loopEnd; pageIdx++) {
                                        /* LGEGMC-754 : 20201123 modify */
                                        var _pageItem = GPC0162.pageTemplate? GPC0162.pageTemplate :'<li><button type="button" value=""></button></li>' ;
                                        /*// LGEGMC-754 : 20201123 modify */
                                        _pageItem = $(_pageItem).get(0);
                                        var _pageButton = _pageItem.querySelector('button');
                                        _pageButton.value = pageIdx;
                                        _pageButton.innerHTML = pageIdx;
                                        if(pageIdx.toString() == pageInfo.page) {
                                            _pageButton.setAttribute('class', 'active');
                                            // WA-GPC0007-01 : aria-current 추가
                                            _pageButton.setAttribute('aria-current', 'page');
                                        }
                                        if($('.pagination', categoryId).attr('data-aria-pattern')) {
                                            var _ariaText = $('.pagination', categoryId).attr('data-aria-pattern').replace(/\#/g, pageIdx);
                                            _pageButton.setAttribute('aria-label', _ariaText);
                                        }
                                        pageMarkup += _pageItem.outerHTML;
                                    }
                                    $('.pagination ul', categoryId).html(pageMarkup); 
    
                                    $('.pagination .prev', categoryId).disabled = !pageInfo.leftPage;
                                    $('.pagination .next', categoryId).disabled = !pageInfo.rightPage;
                                    $('.pagination .prev', categoryId).value = pageInfo.page-1;
                                    $('.pagination .next', categoryId).value = pageInfo.page+1;
                                    $('.pagination ul', categoryId).show();
                                } 
    //                            else if(filter.result.querySelectorAll('.btn-area').length > 0){
    //                                filter.result.querySelector('.btn-area .prev').disabled = !pageInfo.leftPage;
    //                                filter.result.querySelector('.btn-area .next').disabled = !pageInfo.rightPage;
    //                                $(filter.result.querySelector('.btn-area')).show();
    //                            }
                                
                                /* LGEGMC-754 : 20201123 add */
                                if($('.pagination', categoryId).length == 0){
                                    //list-box
                                    var prevFlag = '';
                                    var nextFlag = '';
                                    if(pageInfo.leftPage != true) prevFlag = ' disabled';
                                    if(pageInfo.rightPage != true) nextFlag = ' disabled';
                                    
                                    var pageTemplate = '';
                                    var pageMarkup = [];
                                    pageTemplate += '<div class="pagination" role="navigation" aria-label="'+ $('input[name=component-Pagination]', categoryId).val() +'" data-aria-pattern="'+ $('input[name=component-page]', categoryId).val() +' #">';
                                    pageTemplate += '<button type="button" value="1" class="prev" aria-label="'+ $('input[name=component-previousPage]', categoryId).val() +'"'+prevFlag+'>'+ $('input[name=component-prev]', categoryId).val() +'</button>';       
                                    
                                    pageTemplate += '<ul>';
                                    for (var pageIdx = pageInfo.loopStart; pageIdx <= pageInfo.loopEnd; pageIdx++) {
                                        var _pageItem = '<li><button type="button" value=""></button></li>';
                                        _pageItem = $(_pageItem).get(0);
                                        var _pageButton = _pageItem.querySelector('button');
                                        _pageButton.value = pageIdx;
                                        _pageButton.innerHTML = pageIdx;
                                        if(pageIdx.toString() == pageInfo.page) {
                                            _pageButton.setAttribute('class', 'active');
                                            // WA-GPC0007-01 : aria-current 추가
                                            _pageButton.setAttribute('aria-current', 'page');
                                        }
                                        pageMarkup += _pageItem.outerHTML;
                                    }
                                    pageTemplate += pageMarkup;
                                    pageTemplate += '</ul>';                        
                                    pageTemplate += '<button type="button" value="2" class="next" aria-label="'+ $('input[name=component-nextPage]').val() +'"'+nextFlag+'>'+ $('input[name=component-next]').val() +'</button>';                       
                                    pageTemplate += '</div>';
                                    $('.list-box', categoryId).after(pageTemplate);
                                }
                                
                                if(pageInfo.loopStart != pageInfo.loopEnd && $('.expander', categoryId).length == 0) {
                                    var pageTemplate = '';
                                    pageTemplate += '<div class="expander">';
                                    pageTemplate += '<button type="button" class="btn btn-outline-secondary btn-sm" value="2">'+ $('input[name=component-loadMore]', categoryId).val() +'</button>';
                                    pageTemplate += '</div>';
                                    $('.list-box', categoryId).after(pageTemplate);
                                }
                                /* LGEGMC-754 : 20201123 add */
                                
                                if(isMobile && pageInfo.loopStart != pageInfo.loopEnd) {
                                    $('.pagination', categoryId).hide();
                                    $('.expander', categoryId).show();
                                }
                                
                                if($('.compare-wrap').length>0) {
                                    // for compare product
                                    if(pageInfo.page <= 1) {
                                        $('.btn-area .prev', categoryId).disabled = true; // disabled
                                    } else {
                                        $('.btn-area .prev', categoryId).disabled = false; // enabled
                                    }
                                    if(pageInfo.page == pageInfo.loopEnd) {
                                        $('.btn-area .next', categoryId).disabled = true; // disabled
                                    } else {
                                        $('.btn-area .next', categoryId).disabled = false; // enabled
                                    }
                                }
                                /* BTOBGLOBAL-434 : 20210414 add */
                                if(!!initMobile){                                   
                                    $('.expander button', categoryId).value = Number($("#categoryForm").find('input[type=hidden][name=page]').val()) + 1;
                                }
                                /*// BTOBGLOBAL-434 : 20210414 add */
                            }else {
                                $('.pagination', categoryId).remove();
                                // .btn-area should not be hidden in compare product page.
    //                            if($('.compare-wrap').length==0) {
    //                                // for plp page
    //                                $(filter.result.querySelector('.btn-area')).hide();
    //                            } else {
    //                                // for compare product
    //                                filter.result.querySelector('.btn-area .prev').disabled = !pageInfo.leftPage;
    //                                filter.result.querySelector('.btn-area .next').disabled = !pageInfo.rightPage;
    //                                $(filter.result.querySelector('.btn-area')).show();
    //                            }
                            }
                            GPC0162.addAriaDescribedby($("input[name='categoryId']", "#categoryForm").val());
                        }
                        
                        if(thumbnailLoop) {
                            $("#resultAppendTarget_" + $("input[name='categoryId']", "#categoryForm").val(), categoryId).trigger('thumbnailCarousel');
                        }
                        
                        $(form).data('focus', false);
                        $('body').trigger('initialized-plp');
                        
                        if($('.share-common').length > 0){
                            initShareCommon();
                        }
                        
                        var $item_width = $(categoryId, ".GPC0162").find('.list-box').find('li > .item').outerWidth();
                        
                        if ($(window).width() < 767 || isMobile){
                            $('.pd-share').find('.list').addClass('mobileSns');
                        } else{
                            $('.pd-share').find('.list').css('width', $item_width);
                            $('.pd-share').find('.list').removeClass('mobileSns');
                        }
                        
                        $(".item-fill", ".GPC0162").each(function() {
                            var selectModelId = $(this).attr("data-model-id");
                            
                            $(categoryId, ".GPC0162").find(".list-box li").each(function() {
                                if (selectModelId == $(this).attr("data-model-id")) {
                                    $(".btn-add-package", $(this)).addClass("is-selected");
                                }
                            });
                        });   
                    }
                }
            }, null, 'body');
        }
        , createProductItem : function(productList, productMessages, data) {
            var html = [];
            
            var hasMemberFlag = 'N'; //PJTMEMBERSHIP-11 add
            var ynProductTag = "N";
            var ynBrandTag = "N";
            var ynSibling = "N";
            var ynEnergyLabel = "N";
            var ynPrice = "N";
            /* 2020.10.12 PJTPLP 수정 */
            var ynReviewRating = "N";
            var tagContentAreaYn = data.tagContentAreaYn;
            var labelUseFlag = data.labelUseFlag;
            var useLabelIcon = data.useLabelIcon;
            var modelBrandAreaYn = data.modelBrandAreaYn;
            // PJTPROCOM-3 ADD
            var reviewType = data.reviewType;
            var loginUseFlag = data.loginUseFlag;
            var wishUseFlag = data.wishUseFlag;
            var reviewLoginUrl = data.reviewLoginUrl;
            var dataCheck = data.dataCheck;
            var tmpDomain = data.domain;
            var loginCheck = false;
            //PJTMEMBERSHIP-11 add
            
            //LGEFR-640 Start
            var repairModelAreaYn = data.repairModelAreaYn;
            //LGEFR-640 End
            
            for (var i = 0; i < productList.length; i++){
                if(productList[i].membershipDisplayFlag == 'Y'){
                    hasMemberFlag = 'Y';
                }
            }
            
            //LGCOMPL-218 start
            var obsLowestPriceFlag = 'N';
            for (var i = 0; i < productList.length; i++){
                if(productList[i].obsLowestPriceFlag == 'Y'){
                    obsLowestPriceFlag = 'Y';
                    break;
                }
            }
            //LGCOMPL-218 end
            
            if (GPC0162.accessToken) {
                loginCheck = true;
            }
            
            for (var i = 0; i < productList.length; i++) {
                
                var p           = productList[i],
                    template    = GPC0162.productTemplate;
                    
                var priceValue = "";
                var settingPriceValue = "";
                
                if(p.rPromoPrice != null && p.rPromoPrice != "" && p.rPromoPrice != 'null'){
                    priceValue = p.rPromoPrice+"."+nvl(p.rPromoPriceCent,'00');
                } else if (p.rPrice != null && p.rPrice != "" && p.rPrice != 'null'){
                    priceValue = nvl(p.rPrice,'')+"."+nvl(p.rPriceCent,'00');
                } else {
                    priceValue = 0;
                }
                
                if (loginCheck) {
                    if (p.vipPriceFlag == "Y") {
                        settingPriceValue = priceValue;
                    } else if(p.rMembershipPrice != null && p.rMembershipPrice != "" && p.rMembershipPrice != 'null'){
                        settingPriceValue = p.rMembershipPrice+"."+nvl(p.rMembershipPriceCent,'00');
                    } else {
                        settingPriceValue = priceValue;
                    }
                } else {
                    settingPriceValue = priceValue;
                }
                
                template = template.replace(/\*modelId\*/g, p.modelId)
                                .replace(/\*modelName\*/g, p.modelName)
                                .replace(/\*modelName_toLowerCase\*/g, p.modelName.toLowerCase())
                                .replace(/\*imageAltText\*/g, (p.imageAltText != null) ? p.imageAltText : p.userFriendlyName == null ? '' : p.userFriendlyName.replace(/\"/g, "''"))
                                // 20200325 START 박지영 - ufn 따옴표 처리
                                .replace(/\*userFriendlyName\*/g, p.userFriendlyName == null ? '' : p.userFriendlyName.replace(/\"/g, "''"))
                                // 20200325 END
                                .replace(/\*salesModelCode\*/g, p.salesModelCode)
                                .replace(/\*modelUrlPath\*/g, p.modelUrlPath)
                                .replace(/\*mediumImageAddr\*/g, p.mediumImageAddr)
                                .replace(/\*smallImageAddr\*/g, p.smallImageAddr)
                                /* LGEPL-80 */
                                .replace(/\*promotionLinkUrl\*/g, p.promotionLinkUrl ? p.promotionLinkUrl : "")
                                //LGEGMC-2592
                                // PJTOBS 20200703 Start 
                                .replace(/\*reStockAlertUrl\*/g, p.reStockAlertUrl ? p.reStockAlertUrl : "")
                                // PJTOBS 20200703 End
                                /* LGEUS-12083 : 20190826 add */
                                .replace(/\*rDiscountedPrice\*/g, p.rDiscountedPrice ? changeFormatPrice(p.rDiscountedPrice) : 'null')
                                .replace(/\*rDiscountedPriceCent\*/g, p.rDiscountedPriceCent)
                                /* //LGEUS-12083 : 20190826 add */
                                /* in-house review rating add */
                                .replace(/\*reviewRatingStar2\*/g, p.reviewRatingStar2)
                                .replace(/\*reviewRating\*/g, p.reviewRating)
                                .replace(/\*reviewRatingPercent\*/g, p.reviewRatingPercent)
                                /* // in-house review rating add */
                                .replace(/\*discountedRate\*/g, p.discountedRate)
                                .replace(/\*retailerPricingText\*/g, p.retailerPricingText)
                                .replace(/\*salesSuffixCode\*/g, (p.salesSuffixCode || ''))/* LGEGMC-455 20200717 add */
                                .replace(/\*modelYear\*/g, (nvl(p.modelYear,'') || '')) /* LGEGMC-1279 : 2021.03.12 add */
                                .replace(/\*buName1\*/g, (p.buName1 || ''))
                                .replace(/\*buName2\*/g, (p.buName2 || ''))
                                .replace(/\*buName3\*/g, (nvl(p.buName3,'') || ''))
                                .replace(/\*bizType\*/g, (p.bizType || ''))
                                .replace(/\*superCategoryName\*/g, (p.superCategoryName || ''))
                                .replace(/\*categoryName\*/g, (p.categoryName || ''))
                                .replace(/\*categoryEngName\*/g, (p.categoryEngName || ''))
                                .replace(/\*priceValue\*/g, priceValue) /* LGEGMC-712 20201020 add */
                                .replace(/\*salesSuffixCode\*/g, (p.salesSuffixCode || ''))  /* LGEGMC-455 20200717 add */
                                /* PJTPLP-10 (황규하) wish 기능 추가 START */
                                .replace(/\*wishTotalCnt\*/g, p.wishTotalCnt)
                                .replace(/\*pdFav\*/g, p.myWishCnt == 'Y' ? 'pd-fav on' : 'pd-fav')
                                .replace(/\*icoFav\*/g, p.myWishCnt == 'Y' ? 'ico-fav on' : 'ico-fav')
                                .replace(/\*ariaChecked\*/g, p.myWishCnt == 'Y' ? 'true' : 'false')
                                .replace(/\*modelCopyUrl\*/g, p.modelUrlPath)
                                .replace(/\*categoryName\*/g, p.categoryName)
                                /* PJTPLP-10 (황규하) wish 기능 추가 END */
                                //PJTOBSB2E-3 Start
                                .replace(/\*obsPreOrderStartDate\*/g, p.obsPreOrderStartDate)
                                .replace(/\*obsPreOrderEndDate\*/g, p.obsPreOrderEndDate)
                                .replace(/\*emiPopUrl\*/g, p.obsEmiMsgFlag == 'Y' && p.emiPopUrl != null ? p.emiPopUrl : '')
                                .replace(/\*emiMsg\*/g, p.obsEmiMsgFlag == 'Y' && p.emiMsg != null && p.emiMsg != '' ? p.emiMsg : '')
                                //PJTOBSB2E-3 End 
                                .replace(/\*calculatorSalesCode\*/g, (p.salesModelCode != null && p.salesModelCode != "") && (p.salesSuffixCode != null && p.salesSuffixCode != "") ? p.salesModelCode+"."+p.salesSuffixCode : p.salesModelCode) // LGEGMC-2434, LGEGMC-2589
                                //LGEGMC-1406
                                .replace(/\*specMsg\*/g, p.specMsgFlag == 'Y' ? "<p>"+specMsg+"</p>" : '')
                                //PJTOBSB2E-3 End
                                .replace(/\*msrp\*/g, nvl(p.msrp,'0'))
                                .replace(/\*membershipPrice\*/g, changeFormatFullPrice(p.rMembershipPrice, p.rMembershipPriceCent)) /* PJTMEMBERSHIP-4 */
                                .replace(/\*membershipDisplayFlag\*/g, p.membershipDisplayFlag) /* PJTMEMBERSHIP-4 */
                                .replace(/\*cheaperPrice\*/g, changeFormatFullPrice(p.cheaperPrice, p.cheaperPriceCent)) /* LGEGMC-1973 */
                                .replace(/\*cheaperPriceFlag\*/g, p.cheaperPriceFlag) /* LGEGMC-1973 */
                                .replace(/\*afterPayInstallMent\*/g, p.obsEmiMsgFlag == 'Y' && p.emiMsg != null && p.emiMsg != '' && (p.afterPay <= 3000 && p.afterPay > 0) ? 'afterpay-installment" href="#modal-afterpay' : '" style="display:none;')/* LGEAU-378 add */
                                .replace(/\*wtbClass\*/g,(p.wtbExternalLinkUseFlag =="Y" && p.wtbExternalLinkUrl != null && p.wtbExternalLinkUrl != '' && p.wtbExternalLinkName != null && p.wtbExternalLinkName != '') ? 'in-buynow' : 'where-to-buy') //LGEGMC-2202
                                .replace(/\*isHasMember\*/g,(hasMemberFlag =="Y") ? 'has-member' : '')  /* PJTMEMBERSHIP-11 */
                                .replace(/\*obsPartnerUrl\*/g, nvl(p.obsPartnerUrl, '')) //LGEEG-154
                                .replace(/\*zipPayInstallMent\*/g, p.obsEmiMsgFlag == 'Y' && (p.afterPay > 0 && p.afterPay <= 10000) ? 'afterpay-installment" href="#modal-afterpay' : '" style="display:none;')/* LGEGMC-3167 add, OBSLGEAU-749 (5000 -> 10000) */
                                .replace(/\*orZipPay\*/g, p.obsEmiMsgFlag == 'Y' && (p.afterPay > 0 && p.afterPay <= 3000) ? 'or ' : '')/* LGEGMC-3167 add */
                                .replace(/\*obsLeadTimeMin\*/g, p.obsLeadTimeMin) /*LGEVN-619 add*/
                                .replace(/\*obsLeadTimeMax\*/g, p.obsLeadTimeMax) /*LGEVN-619 add*/
                                .replace(/\*settingPriceValue\*/g, settingPriceValue)
                                .replace(/\*modelRollingImgList\*/g, p.modelRollingImgList);
                                ;
                                
                var tagType         = "";
                var tag1RemoveFlag  = false;
                var tag2RemoveFlag  = false;
                
                // LGEVN-819 Start
                if (tagContentAreaYn == "Y") {
                    if (p.preOrderTagEnableFlag != "Y") {
                        if (p.obsComTagShowFlag == "Y") {
                            
                            if (p.productTag1 != "") {
                                if (p.productTag1Type == "OBS") {
                                    tagType         = "tag-imp";
                                } else if (p.productTag1Type == "COM") {
                                	if(p.productTag1ColorFlag == "Y"){
                                		tagType		= "tag-gold";
                                	} else{
                                		tagType		= "";
                                	}
                                }
                                
                            template = template.replace(/\*productTag1\*/g, p.productTag1)
                                        .replace(/\*tagType1\*/g, tagType)
                                        .replace(/\*productTag1UserType\*/g, p.productTag1UserType);
                            } else {
                                tag1RemoveFlag = true;
                            }
                            
                            if (p.productTag2 != "") {
                                if (p.productTag2Type == "OBS") {
                                    tagType         = "tag-imp";
                                } else if (p.productTag2Type == "COM") {
                                	if(p.productTag2ColorFlag == "Y"){
                                		tagType		= "tag-gold";
                                	} else{
                                        tagType		= "";
                                	}
                                }
                                
                            template = template.replace(/\*productTag2\*/g, p.productTag2)
                                        .replace(/\*tagType2\*/g, tagType)
                                        .replace(/\*productTag2UserType\*/g, p.productTag2UserType);
                            } else {
                                tag2RemoveFlag = true;
                            }
                        } else {
                            if (p.productTag1 != "") {
                                if ((p.limitSaleUseFlag == "Y" && p.obsLimitSale == "Y") || (p.obsPreOrderFlag == 'Y' || p.obsPreOrderRSAFlag == "Y")) {
                                    tagType         = "tag-imp";
                                } else if(p.productTag1ColorFlag == "Y"){
                                	tagType			= "tag-gold";
                                } else {
                                    tagType         = "";
                                }
                                
                                template = template.replace(/\*productTag1\*/g, p.productTag1)
                                        .replace(/\*tagType1\*/g, tagType)
                                        .replace(/\*productTag1UserType\*/g, p.productTag1UserType);
                            } else {
                                tag1RemoveFlag = true;
                            }
                            
                            if (p.productTag2 != "") {
                                if (p.limitSaleUseFlag == "Y" && p.obsLimitSale == "Y") {
                                    tagType         = "tag-imp";
                                } else if (p.productTag2ColorFlag == "Y"){
                                	tagType			= "tag-gold";
                                } else {
                                    tagType         = "";
                                }
                                
                                template = template.replace(/\*productTag2\*/g, p.productTag2)
                                        .replace(/\*tagType2\*/g, tagType)
                                        .replace(/\*productTag2UserType\*/g, p.productTag2UserType);
                            } else {
                                tag2RemoveFlag = true;
                            }
                        }
                    }
                }
                // LGEVN-819 End
                
                var title = $("#openTarget").val();
                p.obsMembershipLinkTarget == '_blank' ? title : title = "";
                var obsMemberShipLinkStartHtml = "";
                var obsMemberShipLinkEndHtml = "";
                if(p.obsMembershipLinkUrl != "" && p.obsMembershipLinkUrl != null && p.membershipDisplayFlag == 'Y'){
                    obsMemberShipLinkStartHtml = "<a href='"+ p.obsMembershipLinkUrl +"' target='"+ p.obsMembershipLinkTarget +"' title='" +title+ "'>";
                    obsMemberShipLinkEndHtml = "</a>"
                } 
                template = template.replace(/\*obsMemberShipLinkStart\*/g,obsMemberShipLinkStartHtml);
                template = template.replace(/\*obsMemberShipLinkEnd\*/g,obsMemberShipLinkEndHtml);
                
                var pdfDownloadFile = $("#pdfDownloadFile").val();
                var rsProductFicheDownload = $("#rsProductFicheDownload").val();
                var rsUseFlag = p.rsUseFlag;
                if(rsUseFlag == "Y"){
                    pdfDownloadFile = rsProductFicheDownload;
                }
                //LGESR-72
                var productFicheDownload = $("#productFicheDownload").val();
                //LGEGMC-5315
				var docTypeCodeFlag = p.docTypeCodeFlag;
				if(docTypeCodeFlag == "N"){
					var productFicheDownload = $("#productNewFicheDownload").val();
				}else if(docTypeCodeFlag == "O"){
					var productFicheDownload = $("#productOldFicheDownload").val();
				}
				//LGEGMC-5315
                var productFichehtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='"+ p.productFicheDocId +"' data-file='" + p.productFicheFileName + "' data-original='" + p.productFicheOriginalName + "' data-category='' title='" + pdfDownloadFile + "' class='link-text'>"
                + "<span class='fiche type-product'>" + productFicheDownload + "</span>"
                + "</a>";
                
                if($('html').attr('data-countrycode') == 'uk'){
                    if(p.energyLabel != "" && p.energyLabel != "N" && p.energyLabel != null && p.energyLabelDocId !=null && p.energyLabelDocId !="" && p.energyLabelFileName != null && p.energyLabelFileName != "" && p.energyLabelOriginalName !=null
                            && p.energyLabelOriginalName !="" && p.energyLabelImageAddr !=null && p.energyLabelImageAddr !="" && p.energyLabelName !=null && p.energyLabelName !="" && p.fEnergyLabelFileName!= null && p.fEnergyLabelDocId!= null && p.fEnergyLabelDocId!= ""&&p.fEnergyLabelFileName!='' && p.fEnergyLabelOriginalName !=null&& p.fEnergyLabelOriginalName !=''){
                        var energyLabelhtml = "<div class='energy-label-wrap'><a href='#' class='label-link'><span class='label'><img src='"+ p.energyLabelImageAddr +"' alt='"+ p.energyLabelName +"'></span></a>"
                        var energyLabelImagehtml = "<div class='tooltip-link'><div class='tolltip-inner'>";
                        if(p.fEnergyLabelFileName!= null && p.fEnergyLabelDocId!= null && p.fEnergyLabelDocId!= ""&&p.fEnergyLabelFileName!='' && p.fEnergyLabelOriginalName !=null&& p.fEnergyLabelOriginalName !=''){
                            energyLabelImagehtml += "<a href='#' class='link-text link-text-uk' adobe-click='pdp-file-down-click' data-doc='" + p.fEnergyLabelDocId + "' data-file='" + p.fEnergyLabelFileName + "' data-original='" + p.fEnergyLabelOriginalName + "'  data-category='' title='" + pdfDownloadFile + "'>"+$("#pdfDownloadFileUk").val()+"</a>";
                        }
                    energyLabelImagehtml += "<a href='#' class='link-text link-text-eu' adobe-click='pdp-file-down-click' data-doc='" + p.energyLabelDocId + "' data-file='" + p.energyLabelFileName + "' data-original='" + p.energyLabelOriginalName + "'  data-category='' title='" + pdfDownloadFile + "'>"+$("#pdfDownloadFileEu").val()+"</a></div></div></div>"
                    }else{
                        var energyLabelhtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.energyLabelDocId + "' data-file='" + p.energyLabelFileName + "' data-original='" + p.energyLabelOriginalName + "' class='link-text' data-category='' title='" + pdfDownloadFile + "'>"
                        + "<span class='label type-none'>";
                        var energyLabelImagehtml = "<img src='"+ p.energyLabelImageAddr +"' alt='"+ p.energyLabelName +"'></span></a>";
                    }
                }else{
                    var energyLabelhtml = "<a href='#' adobe-click='pdp-file-down-click' data-doc='" + p.energyLabelDocId + "' data-file='" + p.energyLabelFileName + "' data-original='" + p.energyLabelOriginalName + "' class='link-text' data-category='' title='" + pdfDownloadFile + "'>"
                    + "<span class='label type-none'>";
                    var energyLabelImagehtml = "<img src='"+ p.energyLabelImageAddr +"' alt='"+ p.energyLabelName +"'></span></a>";
                }
                /*LGEGMC-1035 end*/
                //LGEGMC-5315
				if(docTypeCodeFlag == "N" || docTypeCodeFlag == "O"){
					if(p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId != ""){
						template = template.replace(/\*productFileName2\*/g,productFichehtml);
						template = template.replace(/\*productFileName\*/g,"");
						ynEnergyLabel = "Y";
					} else{
						template = template.replace(/\*productFileName2\*/g,"");
						template = template.replace(/\*productFileName\*/g,"");
					}
				}else{
					if(p.productFicheFileName !="" && p.productFicheOriginalName !="" && p.productFicheFileName != null && p.productFicheOriginalName !=null && p.productFicheDocId !=null && p.productFicheDocId != ""){
						template = template.replace(/\*productFileName\*/g,productFichehtml);
						template = template.replace(/\*productFileName2\*/g,"");
						ynEnergyLabel = "Y";
					} else{
						template = template.replace(/\*productFileName\*/g,"");
						template = template.replace(/\*productFileName2\*/g,"");
					}
				}
				//LGEGMC-5315
                if(p.energyLabel != "" && p.energyLabel != "N" && p.energyLabel != null && p.energyLabelDocId !=null && p.energyLabelDocId !="" && p.energyLabelFileName != null && p.energyLabelFileName != "" && p.energyLabelOriginalName !=null
                        && p.energyLabelOriginalName !="" && p.energyLabelImageAddr !=null && p.energyLabelImageAddr !="" && p.energyLabelName !=null && p.energyLabelName !=""){
                    template = template.replace(/\*energyLabel\*/g,energyLabelhtml);
                    template = template.replace(/\*energyLabelImage\*/g,energyLabelImagehtml);
                        ynEnergyLabel = "Y";
                } else{
                    template = template.replace(/\*energyLabelImage\*/g, "");
                    template = template.replace(/\*energyLabel\*/g,"");
                }
                
                //LGEFR-640 Start
                var repairabilityArea = $(template).find('.repairability-index');
                var repairabilityMsg = repairabilityArea.attr('data-repairability-msg');
                var targetBlankMsg = repairabilityArea.attr('data-target-blank-msg');
                
                if (repairModelAreaYn == "Y") {
                    if(p.labelRepairMap.length > 0){    
                        var repairMapData = p.labelRepairMap[0];
                        
                        var repairAbilityHtml  = "<div class='score'>";
                            repairAbilityHtml  += "<span class='txt'>"+repairabilityMsg+"</span>";
                        if(repairMapData.linkUrl != 'null' && repairMapData.linkUrl != ''){
                            repairAbilityHtml  += "<a href='"+repairMapData.linkUrl+"'";
                            if(repairMapData.linkOpt =='S'){
                                repairAbilityHtml  += " target='_self'";
                            }else if(repairMapData.linkOpt =='B'){
                                repairAbilityHtml  += " target='_blank'";
                            }
                            repairAbilityHtml  += " title='"+targetBlankMsg+"' class='link-pdf'>";
                        } else {
                            repairAbilityHtml += "<span class='link-pdf'>";
                        }
                        
                        if(repairMapData.imagePathAddr != 'null' && repairMapData.imagePathAddr != ''){
                            repairAbilityHtml  += "<img src='"+repairMapData.imagePathAddr+"' alt='"+repairMapData.altText+"'/>";
                        }
                        if(repairMapData.linkUrl != 'null' && repairMapData.linkUrl != ''){
                            repairAbilityHtml  += "</a></div>";
                        } else {
                            repairAbilityHtml  += "</span></div>";
                        }
                        template = template.replace(/\*repairabilityIndex\*/g, repairAbilityHtml);
                    }else{
                        template = template.replace(/\*repairabilityIndex\*/g, "");
                    }
                } else {
                    template = template.replace(/\*repairabilityIndex\*/g, "");
                }
                //LGEFR-640 End
                
                var $template = $(template),
                    $keyBlocks = $template.find('*[data-key]'),
                    $loopBlocks = $template.find('*[data-loop]');
                
                if (loginUseFlag != "Y" || wishUseFlag != "Y") {
                    $template.find(".wish-area").remove();
                }
                
                if (tagContentAreaYn != "Y") {
                    $template.find(".tag-content").remove();
                } else if (tag1RemoveFlag && tag2RemoveFlag) {
                    $template.find(".tag-content").remove();
                } else if (tag1RemoveFlag) {
                    $template.find(".tag-content").find(".tag1").remove();
                } else if (tag2RemoveFlag) {
                    $template.find(".tag-content").find(".tag2").remove();
                }
                
                //LGEFR-640 Start
                if (repairModelAreaYn != "Y") {
                    $template.find(".repairability-index").remove();
                }
                //LGEFR-640 End
                
                //PJTMEMBERSHIP-11 add
                if(p.membershipDisplayFlag != 'Y'){
                    $template.find('.member-text').remove();
                }
                
                /* LGCOMPA-44 start */
                if(  SIGN_IN_STATUS == 'N'
                  && !!p.membershipDisplayFlag
                  &&   p.membershipDisplayFlag == 'Y'
                  && !!data.guestPriceMessageUseFlag
                  &&   data.guestPriceMessageUseFlag == 'Y'
                  && !!data.guestPriceMessage ){
                	$template.find('.member-text').html('').append(data.guestPriceMessage);
                }
                /* LGCOMPA-44 end */
                
                if (p.vipPriceFlag == "Y") {
                    $template.find(".price-vip-Installment").removeClass("d-none");
                    // LGEMS-432 Start
                    if(COUNTRY_CODE.toLowerCase() == 'mx' && 'B2B' == $('.navigation').attr('data-obs-group')){
                    	$template.find(".price-vip-Installment").find(".price-b2b").removeClass("d-none");
                    } else {
                    	$template.find(".price-vip-Installment").find(".price-vip").removeClass("d-none");
                    }
                    // LGEMS-432 End
                } else {
                    $template.find(".price-vip-Installment").remove();
                }
                
                if (modelBrandAreaYn == "Y") {
                    var signature   = $template.find('template[data-signatureFlag]').clone().html();
                    var thinq       = $template.find('template[data-thinqFlag]').clone().html();
                    
                    $template.find('template[data-signatureFlag]').remove();
                    $template.find('template[data-thinqFlag]').remove();
                    
                    if (p.signatureFlag == "Y") {
                        $template.find(".model-brand").append($(signature).get(0).outerHTML);
                    }
                    
                    if (p.thinqFlag == "Y") {
                        $template.find(".model-brand").append($(thinq).get(0).outerHTML);
                    }
                }
                
                for (var i1 = 0; i1 < $keyBlocks.length; i1++) {
                    var $currentKeyBlock = $keyBlocks.eq(i1),
                        key = $currentKeyBlock.get(0).getAttribute('data-key'),
                        val = p[key];
                    if(!val || (val == null || val == "N")) {
                        if($currentKeyBlock.is('.btn')) {
                            $currentKeyBlock.removeClass('active');
                        }else {
                            var countryCode = $('[data-countrycode]').attr('data-countrycode');
                            //PJTLIMITQTY-1 ADD
                            //PJTLIMITQTY_EXTEND ADD
                                if((p.obsLimitSale == 'Y' && p.limitSaleUseFlag == 'Y') || (p.obsPreOrderFlag == "Y" || p.obsPreOrderRSAFlag == "Y")){
                                    $keyBlocks.closest('p').addClass('tag-imp');
                                    if(p.vipPriceFlag != 'Y' && p.obsLimitSale == 'Y' && p.limitSaleUseFlag == 'Y') {
                                        $template.find('.price-vip').text(p.limitSaleTitle);
                                    }
                                }
                                
                                if($template.find('.model-buy').length > 0 && (p.obsLimitSale == 'Y' && p.limitSaleUseFlag == 'Y')){
                                    if($template.find('.only-price').length > 0){
                                        $template.find('.model-buy').removeClass('only-price'); 
                                    }
                                }
                            if((key == 'productTag1' ||  key == 'productTag2')) {
                                $currentKeyBlock.closest('p').remove();
                            }else{
                                $currentKeyBlock.remove();
                            }
                        }
                    }else {
                        if($currentKeyBlock.is('.btn')) {
                            $currentKeyBlock.addClass('active');
                        }else{
                            //PJTLIMITQTY_EXTEND ADD
                            if(p.limitSaleUseFlag == 'Y'){
                                if(p.obsLimitSale == 'Y'){
                                    $keyBlocks.closest('p').addClass('tag-imp');
                                    if(p.vipPriceFlag != 'Y') {
                                        $template.find('.price-vip').text(p.limitSaleTitle);
                                    }
                                }
                                
                                if($template.find('.model-buy').length > 0){
                                    if($template.find('.only-price').length > 0){
                                        $template.find('.model-buy').removeClass('only-price'); 
                                    }
                                }
                            }
                        }
                    }
                }
                
                // price setting
                $priceArea = $template.find('.price-area.total');
                if($priceArea.length!=0) {
                    $priceArea.removeClass('type-none type-default type-msrp type-promotion type-text');
                    if(p.rPromoPrice != null && p.rPrice != null && p.rPromoPrice != '' && p.rPrice != '') {
                    // 20200514 END
                        // type promotion
                        $priceArea.addClass('type-promotion');
                        var price = changeFormatFullPrice(p.rPromoPrice, p.rPromoPriceCent);
                        var pricePromo = changeFormatFullPrice(p.rPrice, p.rPriceCent);
                        $priceArea.find('.purchase-price .price .number').text(price);
                        $priceArea.find('.product-price .price .number').text(pricePromo);
                        //[Start] LGEDE-1151 "has-uvp" 추가
                        if($priceArea.find('.product-price .price.has-uvp').length>0) {
                        	$priceArea.find('.product-price .price.has-uvp .number').text(pricePromo);
                        };
                        //[End] LGEDE-1151
                        $priceArea.find('.product-price .legal').html(p.discountMsg == null ? '' : p.discountMsg.replace(/&lt;/gi, '<').replace(/&gt;/gi, '>'));        // LGEIS-229 change how discounts are shown
                    } else {
                        if(p.rPrice != null && p.rPrice != '') {
                            // type default
                            var price2 = changeFormatFullPrice(p.rPrice, p.rPriceCent);
                            $priceArea.addClass('type-default');
                            $priceArea.find('.purchase-price .price .number').text(price2);
                        }
                    }
                }
                
            	//LGCOMPL-218 start
				if ( obsLowestPriceFlag == 'Y' ) {
					if ( $template.find('.model-buy').length ) $template.find('.model-buy').addClass('has-lowPrice'); 
				}
				if ( p.obsLowestPriceFlag == 'Y' && !!p.rPromoPrice ) {
					if ( $template.find('.lowest-price-mark').length ) $template.find('.lowest-price-mark').removeClass('d-none');
					if ( $template.find('.lowest-price').length ) $template.find('.lowest-price').removeClass('d-none');
					
				} else {
					if ( $template.find('.lowest-price-mark').length ) $template.find('.lowest-price-mark').html('');
					if ( $template.find('.lowest-price').length ) $template.find('.lowest-price').html(''); 
				}
				//LGCOMPL-218 end



                //PJTPROMOAF-1 Start
                if (GPC0162.obsPincodeFlag == "Y" && GPC0162.localeCode != "cl") {
                    if (GPC0162.checkSaleFlag == "N" || p.packageDealBtnFlag == "N") {
                        $template.find(".btn-add-package").addClass("disabled").text($("input[name='btnNotAvailableMsg']", ".GPC0162").val());
                    }
                }
                //PJTPROMOAF-1 End
                
                $template.find('template').remove();
                
//                if(labelUseFlag == "Y" && useLabelIcon == "Y") {
//                    // 2020.10.05 PJTPLP MODIFY END
//                    var $tempThinqSignatureFlag = $template.find('.model-brand');
//                    var thinqFlag = $tempThinqSignatureFlag.find('template[data-thinqFlag]').clone().html();
//                    var signatureFlag = $tempThinqSignatureFlag.find('template[data-signatureFlag]').clone().html();
//                    if(p.signatureFlag == "Y") {
//                        $tempThinqSignatureFlag.append(signatureFlag+" ");
//                        ynBrandTag = "Y";
//                    }
//                    if(p.thinqFlag == "Y") {
//                        $tempThinqSignatureFlag.append(thinqFlag+" ");
//                        ynBrandTag = "Y";
//                    }
//                    // WhiteSpace 관련 수정
//                    if(p.productTag1 != null || p.productTag1 != "" || p.productTag2 != null || p.productTag2 != "") {
//                        ynProductTag = "Y";
//                    }
//                }
                
                // rolling image
                if(p.modelRollingImgList && p.modelRollingImgList != null) {
                    $template.find('.visual img.pc').addClass('js-thumbnail-loop').attr('data-img-list', p.modelRollingImgList);
                }
                
                template = $template.get(0).outerHTML;
                html += template;
            }
            
            var template = html;
            var $template = $(template);
            
            template = $template.get();
            html = template;
            
            return html;
        }
        , addAriaDescribedby : function(categoryId) {
            var waNumber = 0;
            
            $("#resultProductList_" + categoryId, ".GPC0162").find('.list-box .item').each(function() {
                var $target;
                if($(this).find('.model-name a') && !$(this).find('.model-name a').is(':empty')) {
                    $target = $(this).find('.model-name a');
                }
                if($target) {
                    $target.attr('id', 'wa_PLP_'+waNumber);
                    $(this).find('a.btn').attr('aria-describedby', 'wa_PLP_'+waNumber);
                    $(this).find('a.js-compare').attr('aria-describedby', 'wa_PLP_'+waNumber).attr('role', 'button');
                    waNumber++;
                }
            });
        }
        , setBtnEvent : function() {
            // 접근성 관련 sns 포커스 관련 이벤트
            $(document).on('keyup , keydown', '.pd-share', function(e){
                var $snsAct = $(this).find('.list');
                var $lastSns = $snsAct.find('.sns-share li:last-child > a');
                if($snsAct.hasClass('active')){
                    if(e.type == 'keydown'){
                        if($lastSns.is(':focus') && e.shiftKey == false){
                            if(e.keyCode == 9){
                                $snsAct.removeClass('active')
                            }
                        }
                        if(e.shiftKey && e.keyCode == 9 && $(this).find('.sns').is(':focus')){
                            $snsAct.removeClass('active')
                        }
                    } 
                }
            });
            
            $(document).on("click", ".GPC0162 .btn-sns-open", function(e) {
                e.preventDefault();
                
                var snsList = $(this).siblings(".sns-list");
                
                if(snsList.hasClass('active')) {
                    snsList.removeClass('active');
                } else {
                    $(".sns-list", ".GPC0162").removeClass("active");
                    snsList.addClass('active');
                }
            });
            
            $(document).on("click", ".GPC0162 .btn-sns-close", function(e) {
                e.preventDefault();
                $(".sns-list", ".GPC0162").removeClass('active');
            });
            
            $(".btn-category-tab", ".GPC0162").off("click").on("click", function(e) {
                e.preventDefault();
                
                var superCategoryId = $(this).attr("data-super-categoryid");
                var categoryId      = $(this).attr("data-categoryid");
                var divCategoryId   = $(this).attr("href");
                
                $(".btn-category-tab", ".GPC0162").removeClass("active");
                $(this).addClass("active");
                
                $(".category-model-area", ".GPC0162").removeClass("active");
                $(divCategoryId, ".GPC0162").addClass("active");
                
                $("input[name='superCategoryId']", "#categoryForm").val(superCategoryId);
                $("input[name='categoryId']", "#categoryForm").val(categoryId);
                $("input[name='page']", "#categoryForm").val(1);
                $("input[name='pagePosition']", "#categoryForm").val("");
                $(".expander button", "#" + categoryId).val(2);
                
                //PJTPROMOAF-1 Start
//                $("input[name='subCategoryId']", "#categoryForm").val("");
//                var ariaName    = $("select[name='sortBy']", ".GPC0162").data("aria-name");
//                
//                $("select[name='sortBy']", ".GPC0162").attr("aria-controls", ariaName + categoryId);
//                
//                $("input[name='tabChangeFlag']", "#categoryForm").val("Y");
                //PJTPROMOAF-1 End
                
                GPC0162.getProductList();
                
                $('html, body').animate({
                    scrollTop: $("#" + $("input[name='categoryId']").val()).offset().top
                }, 500);
            });
            
            $(document).off("click", ".GPC0162 .btn-del").on("click", ".GPC0162 .btn-del", function(e) {
                e.preventDefault();
                var template        = GPC0162.afterItemTemplate;
                var liArea          = $(this).closest("li");
                var modelId         = liArea.attr("data-model-id");
                var categoryId      = $("input[name='categoryId']").val();
                var selectedItemMsg = $("input[name='selectedItemMsg']").val();
                var offMsg          = $("input[name='offMsg']").val();
                
                // LGEGMCGA-35 Start
                var price           = liArea.attr("data-price");
                var eventName = 'remove_from_package';
    			dataLayer.push({
    				'event'			: eventName,
    				'bu'            : liArea.attr('data-bu'),
    				'superCategory'	: liArea.attr('data-super-category-name'),
    				'category'		: liArea.attr('data-category-name'),
    				'subcategory'	: liArea.attr('data-sub-category-name'),
    				'modelYear'		: liArea.attr('data-model-year'),
    				'modelName'		: liArea.attr('data-model-name'),
    				'modelCode'		: liArea.attr('data-model-id'),
    				'salesModelCode': liArea.attr('data-model-salesmodelcode'),
    				'sku'			: liArea.attr('data-sku'),
    				'suffix'        : liArea.attr('data-model-suffixcode'),
    				'price'         : price,
    				'currencyCode'  : $('.currency-code').val()
    			});
    			digitalDataLayer.push({
    				'event'			: eventName,
    				'bu'            : liArea.attr('data-bu'),
    				'superCategory'	: liArea.attr('data-super-category-name'),
    				'category'		: liArea.attr('data-category-name'),
    				'subcategory'	: liArea.attr('data-sub-category-name'),
    				'modelYear'		: liArea.attr('data-model-year'),
    				'modelName'		: liArea.attr('data-model-name'),
    				'modelCode'		: liArea.attr('data-model-id'),
    				'salesModelCode': liArea.attr('data-model-salesmodelcode'),
    				'sku'			: liArea.attr('data-sku'),
    				'suffix'        : liArea.attr('data-model-suffixcode'),
    				'price'         : price,
    				'currencyCode'  : $('.currency-code').val()
    			});
    			// LGEGMCGA-35 End
                
                $(".list-box > li", "#" + categoryId).each(function() {
                    listModelId = $(this).attr("data-model-id");
                    
                    if (modelId == listModelId) {
                        $(this).find(".btn-add-package").removeClass("is-selected");
                    }
                });
                
                
                if (liArea.nextAll("li.item-fill").length == 0) {
                    liArea.removeClass("item-fill");
                    liArea.addClass("item-before");
                    
                    $(".before-item", liArea).removeClass("d-none");
                    
                    $(".after-item", liArea).remove();
                    liArea.attr("data-model-id", "");
                    liArea.attr("data-price", "");
                    // LGEGMCGA-35 Start
                    liArea.attr("data-bu", "");
                    liArea.attr("data-super-category-name", "");
                    liArea.attr("data-category-name", "");
                    liArea.attr("data-sub-category-name", "");
                    liArea.attr("data-model-year", "");
                    liArea.attr("data-model-name", "");
                    liArea.attr("data-model-salesmodelcode", "");
                    liArea.attr("data-sku", "");
                    liArea.attr("data-model-suffixcode", "");
                    liArea.attr("data-buname-one", "");
                    liArea.attr("data-buname-two", "");
                    liArea.attr("data-buname-three", "");
                    liArea.attr("data-msrp", "");
                    // LGEGMCGA-35 End
                    
                    liArea.append(template);
                } else {
                    var length      = $(".item-fill").length;
                    var index       = liArea.index();
                    var repeatCnt   = length - (index + 1);
                    
                    for (var j=0; j<repeatCnt; j++) {
                        
                        var nowArea     = $(".setting-list > li").eq(index);
                        var nextArea    = $(".setting-list > li").eq(index+1);
                        
                        var cloneArea   = nextArea.find(".after-item").clone();
                        
                        nowArea.attr("data-model-id", nextArea.attr("data-model-id"));
                        nowArea.attr("data-price", nextArea.attr("data-price"));
                        $(".after-item", nowArea).remove();
                        nextArea.find(".after-item").remove();
                        nowArea.append(cloneArea);
                        nowArea.addClass("item-fill");
                        nowArea.removeClass("item-before");
                        
                        if (nowArea.nextAll("li.item-fill").length > 1) {
                            nextArea.addClass("item-fill");
                            nextArea.removeClass("item-before");
                        } else {
                            nextArea.removeClass("item-fill");
                            nextArea.addClass("item-before");
                        }
                    
                        nextArea.attr("data-model-id", "");
                        nextArea.attr("data-price", "");
                        // LGEGMCGA-35 Start
                        nextArea.attr("data-bu", "");
                        nextArea.attr("data-super-category-name", "");
                        nextArea.attr("data-category-name", "");
                        nextArea.attr("data-sub-category-name", "");
                        nextArea.attr("data-model-year", "");
                        nextArea.attr("data-model-name", "");
                        nextArea.attr("data-model-salesmodelcode", "");
                        nextArea.attr("data-sku", "");
                        nextArea.attr("data-model-suffixcode", "");
                        nextArea.attr("data-buname-one", "");
                        nextArea.attr("data-buname-two", "");
                        nextArea.attr("data-buname-three", "");
                        nextArea.attr("data-msrp", "");
                        // LGEGMCGA-35 End
                        nextArea.append(template);
                        $(".before-item", nextArea).removeClass("d-none");
                        index += 1;
                    }
                }
                
                var checkCnt    = $(".item-fill").length;
                            
                if (checkCnt == 0) {
                    $(".item-total").removeClass("active");
                    var cntMsg = "<strong class='ea empty'>" + $(".item-fill", ".GPC0162").length + "</strong> ";
                        cntMsg  += selectedItemMsg + " " + "<span class='rate'>" + nvl($("input[name='discountRate']", ".item-fill:last").val(), "0") + offMsg + "</span>";
                        
                        $(".o-ea").empty();
                        $(".o-ea").append(cntMsg);
                } else {
                    $(".item-total").addClass("active");
                    var cntMsg = "<strong class='ea'>" + $(".item-fill", ".GPC0162").length + "</strong> ";
                    cntMsg  += selectedItemMsg + " " + "<span class='rate'>" + nvl($("input[name='discountRate']", ".item-fill:last").val(), "0") + offMsg + "</span>";
                    
                    $(".o-ea").empty();
                    $(".o-ea").append(cntMsg);
                }
                
                if ($(".item-fill", ".GPC0162").length  <= 1) {
                    $(".setting-price-area", ".item-fill").removeClass("type-promotion");
                    $(".setting-price-area", ".item-fill").addClass("type-default");
                    
                    if ($(".item-fill", ".GPC0162").length == 0) {
                        $(".btn-add-basket", ".GPC0162").addClass("disabled");
                    }
                } else {
                    $(".setting-price-area", ".item-fill").addClass("type-promotion");
                    $(".setting-price-area", ".item-fill").removeClass("type-default");
                }
                
                $(".o-more > span").text($(".before-item", ".GPC0162").length - $(".item-fill", ".GPC0162").length);
                
                var cartModelId = "";
                
                $(".item-fill").each(function() {
                    cartModelId += $(this).attr("data-model-id") + ",";
                });
                
                cartModelId = cartModelId.substring(0, cartModelId.length-1);
                
                $(".btn-add-basket").data("model-id", cartModelId);
                
                GPC0162.calculatePrice();
            });
            
            $(document).off("click", ".GPC0162 .btn-add-package").on("click", ".GPC0162 .btn-add-package", function(e) {
                e.preventDefault();
                
                //PJTPROMOAF-1 Start
                if (GPC0162.obsPincodeFlag == "Y" && GPC0162.localeCode != "cl") {
                    if ($("#pincode", ".GPC0162").val() == "" || (GPC0162.checkSaleFlag != "Y")) {
                        $("#modal_enter_pincode").modal("show");
                        return false;
                    }
                }
                //PJTPROMOAF-1 End
                
                var settingCnt  = $(".setting-list li", ".GPC0162").length;
                var maxFlag     = false;
                
                if ($(".item-fill", ".GPC0162").length == settingCnt) {
                    if (!$(this).hasClass("is-selected")) {
                        $("#modal_package_max").modal("show");
                        maxFlag     = true;
                    }
                }
                
                if (maxFlag) {
                    return false;
                }
                
                var template            = GPC0162.afterItemTemplate;
                var liArea              = $(this).closest("li");
                var modelId             = liArea.attr("data-model-id");
                var modelName           = liArea.attr("data-model-name");
                var userFriendlyName    = liArea.attr("data-user-friendly-name");
                var price               = liArea.attr("data-price");
                var modelRollingImgList = liArea.attr("data-img-list");
                var imageAltText        = liArea.attr("data-image-alt-text");
                var mediumImageAddr     = liArea.attr("data-medium-image-addr");
                var smallImageAddr      = liArea.attr("data-small-image-addr");
                var modelUrlPath        = liArea.attr("data-model-url-path");
                var salesModelCode      = liArea.attr("data-model-salesmodelcode");
                var currencySymbol      = liArea.attr("data-currency-symbol");
                var selectedItemMsg     = $("input[name='selectedItemMsg']").val();
                var offMsg              = $("input[name='offMsg']").val();
                var cartModelId         = "";
                
                // LGEGMCGA-35 Start
                var eventName = '';
                var icoArea = $(this).closest('.item').find('.pd-sideInfo').find('.ico-fav');
                // LGEGMCGA-35 End
                
                if($(this).hasClass("is-selected")) {
                    $(this).removeClass("is-selected");
                    
                    var listModelId = $(this).attr("data-model-id");
                    
                    $(".item-fill").each(function(i) {
                        var selectModelId   = $(this).attr("data-model-id");
                        var flag            = false;
                        //삭제
                        if (listModelId == selectModelId) {
                            
                            if ($(this).nextAll("li.item-fill").length == 0) {
                                $(this).removeClass("item-fill");
                                $(this).addClass("item-before");
                                
                                $(".before-item", $(this)).removeClass("d-none");
                                
                                $(".after-item", $(this)).remove();
                                $(this).attr("data-model-id", "");
                                $(this).attr("data-price", "");
                                // LGEGMCGA-35 Start
                                $(this).attr("data-bu", "");
                                $(this).attr("data-super-category-name", "");
                                $(this).attr("data-category-name", "");
                                $(this).attr("data-sub-category-name", "");
                                $(this).attr("data-model-year", "");
                                $(this).attr("data-model-name", "");
                                $(this).attr("data-model-salesmodelcode", "");
                                $(this).attr("data-sku", "");
                                $(this).attr("data-model-suffixcode", "");
                                $(this).attr("data-buname-one", "");
                                $(this).attr("data-buname-two", "");
                                $(this).attr("data-buname-three", "");
                                $(this).attr("data-msrp", "");
                                // LGEGMCGA-35 End
                                
                                $(this).append(template);
                                flag = true;
                            } else {
                                var length      = $(".item-fill").length;
                                var index       = i;
                                var repeatCnt   = length - (index + 1);
                                
                                for (var j=0; j<repeatCnt; j++) {
                                    
                                    var nowArea     = $(".setting-list > li").eq(index);
                                    var nextArea    = $(".setting-list > li").eq(index+1);
                                    
                                    var cloneArea   = nextArea.find(".after-item").clone();
                                    
                                    nowArea.attr("data-model-id", nextArea.attr("data-model-id"));
                                    nowArea.attr("data-price", nextArea.attr("data-price"));
                                    $(".after-item", nowArea).remove();
                                    nextArea.find(".after-item").remove();
                                    nowArea.append(cloneArea);
                                    
                                    nowArea.addClass("item-fill");
                                    nowArea.removeClass("item-before");
                                    
                                    if (nowArea.nextAll("li.item-fill").length > 1) {
                                        nextArea.addClass("item-fill");
                                        nextArea.removeClass("item-before");
                                    } else {
                                        nextArea.removeClass("item-fill");
                                        nextArea.addClass("item-before");
                                    }
                                
                                    nextArea.attr("data-model-id", "");
                                    nextArea.attr("data-price", "");
                                    // LGEGMCGA-35 Start
                                    nextArea.attr("data-bu", "");
                                    nextArea.attr("data-super-category-name", "");
                                    nextArea.attr("data-category-name", "");
                                    nextArea.attr("data-sub-category-name", "");
                                    nextArea.attr("data-model-year", "");
                                    nextArea.attr("data-model-name", "");
                                    nextArea.attr("data-model-salesmodelcode", "");
                                    nextArea.attr("data-sku", "");
                                    nextArea.attr("data-model-suffixcode", "");
                                    nextArea.attr("data-buname-one", "");
                                    nextArea.attr("data-buname-two", "");
                                    nextArea.attr("data-buname-three", "");
                                    nextArea.attr("data-msrp", "");
                                    // LGEGMCGA-35 End
                                    nextArea.append(template);
                                    $(".before-item", nextArea).removeClass("d-none");
                                    index += 1;
                                    
                                    flag = true;
                                }
                            }
                            
                            if (flag) {
                                return false;
                            }
                        }
                    });
                    
                    var checkCnt    = $(".item-fill").length;
                            
                    if (checkCnt == 0) {
                        $(".item-total").removeClass("active");
                        var cntMsg = "<strong class='ea empty'>" + $(".item-fill", ".GPC0162").length + "</strong> ";
                            cntMsg  += selectedItemMsg + " " + "<span class='rate'>" + nvl($("input[name='discountRate']", ".item-fill:last").val(), "0") + offMsg + "</span>";
                            
                            $(".o-ea").empty();
                            $(".o-ea").append(cntMsg);
                    } else {
                        $(".item-total").addClass("active");
                        var cntMsg = "<strong class='ea'>" + $(".item-fill", ".GPC0162").length + "</strong> ";
                        cntMsg  += selectedItemMsg + " " + "<span class='rate'>" + nvl($("input[name='discountRate']", ".item-fill:last").val(), "0") + offMsg + "</span>";
                        
                        $(".o-ea").empty();
                        $(".o-ea").append(cntMsg);
                    }
                    
                    $(".item-total", ".GPC0162").find(".ea").text($(".item-fill", ".GPC0162").length + " ");
                    
                    GPC0162.calculatePrice();
                    
                    // LGEGMCGA-35 Start
                    eventName = 'remove_from_package';
        			dataLayer.push({
        				'event'			: eventName,
        				'bu'            : icoArea.attr('data-bu'),
        				'superCategory'	: icoArea.attr('data-super-category-name'),
        				'category'		: icoArea.attr('data-category-name'),
        				'subcategory'	: icoArea.attr('data-sub-category-name'),
        				'modelYear'		: icoArea.attr('data-model-year'),
        				'modelName'		: icoArea.attr('data-model-name'),
        				'modelCode'		: icoArea.attr('data-model-id'),
        				'salesModelCode': icoArea.attr('data-model-salesmodelcode'),
        				'sku'			: icoArea.attr('data-sku'),
        				'suffix'        : icoArea.attr('data-model-suffixcode'),
        				'price'         : price,
        				'currencyCode'  : $('.currency-code').val()
        			});
        			digitalDataLayer.push({
        				'event'			: eventName,
        				'bu'            : icoArea.attr('data-bu'),
        				'superCategory'	: icoArea.attr('data-super-category-name'),
        				'category'		: icoArea.attr('data-category-name'),
        				'subcategory'	: icoArea.attr('data-sub-category-name'),
        				'modelYear'		: icoArea.attr('data-model-year'),
        				'modelName'		: icoArea.attr('data-model-name'),
        				'modelCode'		: icoArea.attr('data-model-id'),
        				'salesModelCode': icoArea.attr('data-model-salesmodelcode'),
        				'sku'			: icoArea.attr('data-sku'),
        				'suffix'        : icoArea.attr('data-model-suffixcode'),
        				'price'         : price,
        				'currencyCode'  : $('.currency-code').val()
        			});
        			// LGEGMCGA-35 End
                } else {
                    $(this).addClass("is-selected");
                    
                    var itemArea = $(".item-before").eq(0);
                    
                    itemArea.addClass("item-fill");
                    itemArea.attr("data-model-id", modelId);
                    itemArea.attr("data-price", price);
                    // LGEGMCGA-35 Start
                    itemArea.attr("data-bu", icoArea.attr('data-bu'));
                    itemArea.attr("data-super-category-name", icoArea.attr('data-super-category-name'));
                    itemArea.attr("data-category-name", icoArea.attr('data-category-name'));
                    itemArea.attr("data-sub-category-name", icoArea.attr('data-sub-category-name'));
                    itemArea.attr("data-model-year", icoArea.attr('data-model-year'));
                    itemArea.attr("data-model-name", icoArea.attr('data-model-name'));
                    itemArea.attr("data-model-salesmodelcode", icoArea.attr('data-model-salesmodelcode'));
                    itemArea.attr("data-sku", icoArea.attr('data-sku'));
                    itemArea.attr("data-model-suffixcode", icoArea.attr('data-model-suffixcode'));
                    itemArea.attr("data-buname-one", icoArea.attr('data-buname-one'));
                    itemArea.attr("data-buname-two", icoArea.attr('data-buname-two'));
                    itemArea.attr("data-buname-three", icoArea.attr('data-buname-three'));
                    itemArea.attr("data-msrp", icoArea.attr('data-msrp'));
                    // LGEGMCGA-35 End
                    
                    $(".before-item", itemArea).addClass("d-none");
                    
                    $(".after-item", itemArea).removeClass("d-none");
                    
                    $(".after-item", itemArea).find("div.visual").attr("href", modelUrlPath);
                    $(".after-item", itemArea).find("div.visual").attr("data-link-name", modelName);
                    $(".after-item", itemArea).find("div.visual").attr("data-adobe-modelname", modelName);
                    $(".after-item", itemArea).find("div.visual").attr("data-adobe-salesmodelcode", salesModelCode);
                    
                    $(".after-item", itemArea).find("img.pc").attr("data-src", mediumImageAddr);
                    $(".after-item", itemArea).find("img.pc").attr("data-img-list", modelRollingImgList);
                    $(".after-item", itemArea).find("img.pc").attr("alt", imageAltText);
                    
                    $(".after-item", itemArea).find("img.mobile").attr("data-src", smallImageAddr);
                    $(".after-item", itemArea).find("img.mobile").attr("alt", imageAltText);
                    
                    $(".after-item", itemArea).find(".model-name").text(userFriendlyName);
                    $(".after-item", itemArea).find(".model-sku").text(modelName);
                    
                    $(".after-item", itemArea).find("span.unit").text(currencySymbol);
                    $(".after-item", itemArea).find("div.setting-price-area .purchase-price").find("span.number").text(price);
                    
                    $(".after-item", itemArea).find("img.pc").addClass('js-thumbnail-loop');
                    
                    itemArea.removeClass("item-before");
                    
                    var scrH = $('.order-list-box').offset().top - 10;
                            $('html, body').stop().animate({scrollTop: scrH}, {duration: 500, easing: 'easeInCubic'});
                            $('.btn-del', ".item-fill:last").focus(); // 개발시 담긴 제품으로 포커스
                            
                    // LGEGMCGA-35 Start
                    eventName = 'add_to_package';
        			dataLayer.push({
        				'event'			: eventName,
        				'bu'            : icoArea.attr('data-bu'),
        				'superCategory'	: icoArea.attr('data-super-category-name'),
        				'category'		: icoArea.attr('data-category-name'),
        				'subcategory'	: icoArea.attr('data-sub-category-name'),
        				'modelYear'		: icoArea.attr('data-model-year'),
        				'modelName'		: icoArea.attr('data-model-name'),
        				'modelCode'		: icoArea.attr('data-model-id'),
        				'salesModelCode': icoArea.attr('data-model-salesmodelcode'),
        				'sku'			: icoArea.attr('data-sku'),
        				'suffix'        : icoArea.attr('data-model-suffixcode'),
        				'price'         : price,
        				'currencyCode'  : $('.currency-code').val()
        			});
        			digitalDataLayer.push({
        				'event'			: eventName,
        				'bu'            : icoArea.attr('data-bu'),
        				'superCategory'	: icoArea.attr('data-super-category-name'),
        				'category'		: icoArea.attr('data-category-name'),
        				'subcategory'	: icoArea.attr('data-sub-category-name'),
        				'modelYear'		: icoArea.attr('data-model-year'),
        				'modelName'		: icoArea.attr('data-model-name'),
        				'modelCode'		: icoArea.attr('data-model-id'),
        				'salesModelCode': icoArea.attr('data-model-salesmodelcode'),
        				'sku'			: icoArea.attr('data-sku'),
        				'suffix'        : icoArea.attr('data-model-suffixcode'),
        				'price'         : price,
        				'currencyCode'  : $('.currency-code').val()
        			});
        			// LGEGMCGA-35 End
                }
                
                if ($(".item-fill", ".GPC0162").length > 0) {
                    $(".item-total", ".GPC0162").addClass("active");
                    $(".btn-add-basket", ".GPC0162").removeClass("disabled");
                    
                    if ($(".item-fill", ".GPC0162").length  == 1) {
                        $(".setting-price-area", ".item-fill").removeClass("type-promotion");
                        $(".setting-price-area", ".item-fill").addClass("type-default");
                    } else {
                        $(".setting-price-area", ".item-fill").addClass("type-promotion");
                        $(".setting-price-area", ".item-fill").removeClass("type-default");
                    }
                    
                    var cntMsg = "<strong class='ea'>" + $(".item-fill", ".GPC0162").length + "</strong> ";
                    cntMsg  += selectedItemMsg + " " + "<span class='rate'>" + nvl($("input[name='discountRate']", ".item-fill:last").val(), "0") + offMsg + "</span>";
                    
                    $(".o-ea").empty();
                    $(".o-ea").append(cntMsg);
                    
                    
                } else {
                    $(".item-total", ".GPC0162").removeClass("active");
                    $(".btn-add-basket", ".GPC0162").addClass("disabled");
                    
                    $(".setting-price-area").removeClass("type-promotion");
                    $(".setting-price-area").addClass("type-default");
                    
                    var cntMsg = "<strong class='ea empty'>" + $(".item-fill", ".GPC0162").length + "</strong> ";
                    cntMsg  += selectedItemMsg + " " + "<span class='rate'>" + nvl($("input[name='discountRate']", ".item-fill:last").val(), "0") + offMsg + "</span>";
                    
                    $(".o-ea").empty();
                    $(".o-ea").append(cntMsg);
                }
                
                $(".o-more > span").text($(".before-item", ".GPC0162").length - $(".item-fill", ".GPC0162").length);
                
                $(".item-fill", ".GPC0162").each(function() {
                    cartModelId += $(this).attr("data-model-id") + ",";
                });
                
                cartModelId = cartModelId.substring(0, cartModelId.length-1);
                $(".btn-add-basket", ".GPC0162").data("model-id", cartModelId);
                
                GPC0162.calculatePrice();
            });
            
            $(document).off("click", ".GPC0162 .pagination a, .GPC0162 .pagination button, .GPC0162 .expander button").on("click", ".GPC0162 .pagination a, .GPC0162 .pagination button, .GPC0162 .expander button", function(e) {
                e.preventDefault();
                
                if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_IMPROVEMENTS')  ||COUNTRY_CODE.toLowerCase()=='uk') {
                    var page;
                    if($(e.currentTarget).closest('.expander')[0]) { // lead more button
                        page = e.currentTarget.value;
                        $(categoryForm).data('focus', true);
                    }else { // general page button
                        page = $(e.currentTarget).is('a') ? e.currentTarget.getAttribute('href') : e.currentTarget.value;
    
                        var pagePosition = "";
                        if($(e.currentTarget).is(".prev")) {
                            pagePosition = "L";
                        }else if($(e.currentTarget).is(".next")){
                            pagePosition = "R";
                        }
                        
                        $("input[name='pagePosition']", "#categoryForm").val(pagePosition);
                        $('html, body').animate({
                            scrollTop: $("#" + $("input[name='categoryId']").val()).offset().top
                        }, 500);
                        
                        $("#categoryForm").data('focus', false);
                    }
                    
                    $("input[name='page']", "#categoryForm").val(page);
                    
                    GPC0162.getProductList();

                    if($('.visual').hasClass('hasThumbnail')){
                        setTimeout(function(){
                            $('.visual').addClass('hasThumbnail');
                        }, 300);
                    }
                } else {
                    ePrivacyCookies.view('click');
                }
            });
            
            $('.btn-add-product', ".GPC0162").off("click").on("click", function(e){
                e.preventDefault();
                
                //PJTPROMOAF-1 Start
                if (GPC0162.obsPincodeFlag == "Y" && GPC0162.localeCode != "cl") {
                    
                    if ($("#pincode", ".GPC0162").val() == "" || (GPC0162.checkSaleFlag != "Y")) {
                        GPC0162.pincodeFocus();
                    } else {
                        var scrH = $('.js-tab-category.js-tab-controll-type3').offset().top;
                        $('html, body').stop().animate({scrollTop: scrH}, {duration: 500, easing: 'easeInCubic'});
                        $("#tab_package_deal li:first", ".GPC0162").find('.btn-tab').focus();
                    }
                } else {
                    var scrH = $('.js-tab-category.js-tab-controll-type3').offset().top;
                    $('html, body').stop().animate({scrollTop: scrH}, {duration: 500, easing: 'easeInCubic'});
                    $("#tab_package_deal li:first", ".GPC0162").find('.btn-tab').focus();
                }
                //PJTPROMOAF-1 End
            });
            
            //PJTPROMOAF-1 Start
            $(".btn-pincode-confirm", ".GPC0162").off("click").on("click", function(e){
                e.preventDefault();
                
                var resultArea  = $(".error-msg", ".zip-code");
                var errMsgHtml  = "<span class='check-fail'>" + $("input[name='pinRequiredMsg']", ".GPC0162").val() + "</span>";
                
                resultArea.empty();
                $(".error-msg", ".zip-code").removeClass("d-block");
                $(".field-block", ".zip-code").removeClass("error");
                $("#pincode", ".zip-code").removeAttr("aria-invalid");
                $("#pincode", ".zip-code").removeAttr("aria-describedby");
                
                if ($("#pincode", ".GPC0162").val() != "") {
                    
                    GPC0162.pincodeBtnFlag  = "Y"
                    GPC0162.getProductList();
                } else {
                    resultArea.html(errMsgHtml);
                    
                    $(".btn-pincode-confirm", ".GPC0162").removeClass("d-none");
                    $(".btn-change-pincode", ".GPC0162").addClass("d-none");
                    $("#pincode", ".zip-code").attr("disabled", false);
                    
                    if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "select") {
                        $("#pincode", ".zip-code").trigger("chosen:updated");
                    }
                    
                    $(".error-msg", ".zip-code").addClass("d-block");
                    $(".field-block", ".zip-code").addClass("error");
                    $("#pincode", ".zip-code").attr("aria-invalid", true);
                    $("#pincode", ".zip-code").attr("aria-describedby", $("#pincode", ".zip-code").attr("name") + "Error");
                    
                    if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "input") {
                        $("#pincode", ".zip-code").focus();
                    } else {
                        $("#pincode", ".zip-code").trigger("chosen:activate");
                    }
                }
            });
            
            $(".btn-change-pincode", ".GPC0162").off("click").on("click", function(e) {
                e.preventDefault();
                
                $(".btn-pincode-confirm", ".GPC0162").removeClass("d-none");
                $(".btn-change-pincode", ".GPC0162").addClass("d-none");
                $("#pincode", ".zip-code").attr("disabled", false);
                
                if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "select") {
                    $("#pincode", ".zip-code").trigger("chosen:updated");
                }
                
                $(".error-msg", ".zip-code").addClass("d-none");
                $(".error-msg", ".zip-code").empty();
                $(".field-block", ".zip-code").removeClass("error");
                
                GPC0162.checkSaleFlag = "";
                GPC0162.pincodeBtnFlag = "N";
                $("#pincode", ".GPC0162").val("");
                $("input[name='originPincode']", ".GPC0162").val("");
                $("input[name='checkSaleMessage']", ".GPC0162").val("");
                $("input[name='checkSaleFlag']", ".GPC0162").val("");
                $("input[name='obsModelList']", ".GPC0162").val("");
                
                if ($(".item-fill", ".GPC0162").length > 0) {
                    $("#modal_change_pincode").modal("show");
                } else {
                    GPC0162.pincodeFocus();
                }
                
                GPC0162.getProductList();
            });
            
            $("#modal_enter_pincode").on("hidden.bs.modal", function(e) {
                GPC0162.pincodeFocus();
            });
            
            $("#modal_change_pincode").on("hidden.bs.modal", function(e) {
                var length = $(".item-fill", ".GPC0162").length;
                
                for (var i=0; i<length; i++) {
                    $(".item-fill", ".GPC0162").eq(0).find(".btn-del").trigger("click");
                }
                
                GPC0162.pincodeFocus();
            });
             
            $("#pincode", ".GPC0162").off("keypress").on("keypress", function(e) {
                
                if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "input") {
                    
                    if (e.keyCode == 13) {
                        $(".btn-pincode-confirm", ".GPC0162").trigger("click");
                    }
                }
            });
            
//            $("select[name='sortBy']", ".GPC0162").off("change").on("change", function(e) {
//                e.preventDefault();
//                
//                var value   = $(this).val();
//                
//                $("input[name='page']", "#categoryForm").val("1");
//                $("input[name='subCategoryId']", "#categoryForm").val(value);
//                
//                $("input[name='tabChangeFlag']", "#categoryForm").val("N");
//                
//                GPC0162.getProductList();
//            });
            //PJTPROMOAF-1 End
        }
        , calculatePrice : function() {
            var rate            = Number($("input[name='discountRate']", ".item-fill:last").val());
            var offMsg          = $("input[name='offMsg']").val();
            var subTotal        = 0;
            var discountTotal   = 0;
            var symbolMsg       = $("input[name='currencySymbolMsg']").val();
            var total           = 0;
            var priceCentUseFlag= $("input[name='priceCentUseFlag']").val();
            var currencyPosition= $("input[name='currencyPosition']").val();
            var digitCnt        = 0;
            
            if (priceCentUseFlag != "N") {
                digitCnt        = 2
            }
            
            $(".item-fill", ".GPC0162").each(function (i){
                
                var price           = Number($(this).attr("data-price"));
                var discountPrice   = Number((price * rate) / 100);
                discountPrice       = Number(discountPrice.toFixed(digitCnt));
                
                var calPrice        = Number(price - discountPrice);
                calPrice            = Number(calPrice.toFixed(digitCnt));
                
                subTotal      += price;
                discountTotal += Number(discountPrice);
                price         = Number(price.toFixed(digitCnt));
                
                if (priceCentUseFlag == "N") {
                    $(".product-price", $(this)).find(".number").text(changeFormatPrice(price));
                    $(".product-price", $(this)).find(".legal").text(rate + offMsg);
                    $(".purchase-price", $(this)).find(".number").text(changeFormatPrice(calPrice));
                } else {
                    $(".product-price", $(this)).find(".number").text(GPC0162.numberWithCommas(parseFloat(price).toFixed(digitCnt)));
                    $(".product-price", $(this)).find(".legal").text(rate + offMsg);
                    $(".purchase-price", $(this)).find(".number").text(GPC0162.numberWithCommas(parseFloat(calPrice).toFixed(digitCnt)));
                }
            });
            
            subTotal        = Number(subTotal.toFixed(digitCnt));
            discountTotal   = Number(discountTotal.toFixed(digitCnt));
            
            total   = subTotal - discountTotal;
            total   = Number(total.toFixed(digitCnt));
            
            if (priceCentUseFlag == "N") {
                $(".sub-total-number", ".GPC0162").text(changeFormatPrice(subTotal));
                $(".discount-total-number", ".GPC0162").text(changeFormatPrice(discountTotal));
                
                if (currencyPosition == "L") {
                    $(".total-number", ".GPC0162").text(symbolMsg + (changeFormatPrice(total)));
                } else if (currencyPosition == "R") {
                    $(".total-number", ".GPC0162").text((changeFormatPrice(total)) + symbolMsg);
                }
            } else {
                $(".sub-total-number", ".GPC0162").text(GPC0162.numberWithCommas(parseFloat(subTotal).toFixed(digitCnt)));
                $(".discount-total-number", ".GPC0162").text(GPC0162.numberWithCommas(parseFloat(discountTotal).toFixed(digitCnt)));
                
                if (currencyPosition == "L") {
                    $(".total-number", ".GPC0162").text(symbolMsg + (GPC0162.numberWithCommas(parseFloat(total).toFixed(digitCnt))));
                } else if (currencyPosition == "R") {
                    $(".total-number", ".GPC0162").text((GPC0162.numberWithCommas(parseFloat(total).toFixed(digitCnt))) + symbolMsg);
                }
            }
            
        }
        , numberWithCommas : function(num) {
            var parts = num.split("."); 
            return changeFormatFullPrice(parts[0], (parts[1] ? parts[1] : "00")); 
        }
        //PJTPROMOAF-1 Start
        , pincodeFocus : function() {
            var scrH = $(".zip-code", ".GPC0162").offset().top;
            $('html, body').stop().animate({scrollTop: scrH}, {duration: 500, easing: 'easeInCubic'});
            
            if ($("#pincode", ".zip-code").prop("tagName").toLowerCase() == "input") {
                $("#pincode", ".zip-code").focus();
            } else {
                $("#pincode", ".zip-code").trigger("chosen:updated");
                $("#pincode", ".zip-code").trigger("chosen:activate");
            }
        }
        //PJTPROMOAF-1 End
        , mCustom_init : function() {
            var _this = $(".GPC0162");
            if($(window).outerWidth() > 1025){
                _this.find(".js-tab-category .tab-outer").mCustomScrollbar("destroy");
            }
            $(window).on("resize", function(e){
                var winW = $(window).outerWidth();
                if(winW > 1025){
                    _this.find(".js-tab-category .tab-outer").mCustomScrollbar("destroy")
                } else{
                    if(_this.find(".js-tab-category .tab-outer").hasClass('mCS_destroyed')){
                        _this.find(".js-tab-category .tab-outer").mCustomScrollbar({
                            theme:'dark',
                            axis:"x",
                            advanced:{ autoUpdateTimeout: 100, autoScrollOnFocus: false},
                            keyboard:{ enable: false},
                            scrollInertia: 400,
                            callbacks:{
                                onCreate: function(){
                                    $(this).addClass('mCustomScrollbar_init');
                                },
                                onOverflowXNone: function(){
                                    var _this = this;
                                    $(_this).closest('.tab-wrap').addClass('none-scroll').removeClass('has-scroll');
                                },
                                onOverflowX: function(){
                                    $(this).closest('.tab-wrap').removeClass('none-scroll').addClass('has-scroll');
                                },
                                onScroll: function(){
                                    $(this).trigger('scrolled');
                                },
                                onTotalScroll: function(){
                                    $(this).trigger('totalScroll');
                                },
                                onTotalScrollBack: function(){
                                    $(this).trigger('totalScrollBack');
                                }
                            }
                        });
                    }
                }
            });
        }
    };

    GPC0162.init();
});


function initShareCommon() {
    var shareObj = $('.share-common');
    var sharePrint = shareObj.find('.article-print');
    var shareEmail = shareObj.find('.article-email');
    var shareLink = shareObj.find('.article-link');
    var shareSms = shareObj.find('.article-sms');
    //share
    var shareFB = shareObj.find('.share-facebook');
    var shareTW = shareObj.find('.share-twitter');
    var sharePI = shareObj.find('.share-pinterest');
    var shareVK = shareObj.find('.share-vk');
    var shareOK = shareObj.find('.share-ok');
    var shareLI = shareObj.find('.share-linkedin');
    var shareWB = shareObj.find('.share-weibo');
    var shareWE = shareObj.find('.share-wechat');
    //question section
    var shareModal = $('#modal_resource_search_copylink');

    // for touch device
    if ('ontouchstart' in document.documentElement) {
        shareObj.find('.external-link.mobile-only').css('display', 'inline-block');
    }

    // for wechat
    if(shareWE.length>0) {
        if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_SOCIAL_MEDIA')) {
            window._bd_share_config = {
                "common": {
                    bdSnsKey: {},
                    bdText: $(".bdsharebuttonbox").data("text"),
                    bdMiniList: !1,
                    bdUrl: $(".bdsharebuttonbox").data("url"),
                    bdPic: "",
                    bdSize: "32"
                },
                "share": {}
            };
            with(document) 0[(getElementsByTagName('head')[0] || body).appendChild(createElement('script')).src = '/cn/baidumap/baiduShare-master/static/api/js/share.js?v=89860593.js'];
        }
    }

    $(document)
        .on("click", "[type=submit][form]", function (event) {
            event.preventDefault();
            var formId = $(this).attr("form"),
                $form = $("#" + formId).submit();
        })
        .on("keypress", "form input", function (event) {
            var $form;
            if (event.keyCode == 13) {
                $form = $(this).parents("form");
                if ($form.find("[type=submit]").length == 0 &&
                    $("[type=submit][form=" + $(this).attr("form") + "]").length > 0) {
                    $form.submit();
                }
            }
        });

    // adobe
    function adobeShare(obj, name) {
        // for PDP
        if ($('.GPC0009').length>0) {
            adobeTrackEvent('share-print', {
                products: [{sales_model_code : $('.GPC0009').data('adobe-salesmodelcode'), model_name: $('.GPC0009').data('adobe-modelname')}],
                social_service_name: name,
                page_event: {sns_share: true}
            });
        } else {
            adobeTrackEvent('sns-share', {social_service_name: name, page_event: {sns_share: true}});
        }
    }
    sharePrint.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'print');
        window.print();
    });
    shareEmail.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'email');
        var title = encodeURIComponent(document.title),
            hashCheck = new RegExp(/\#$/g);
        if(hashCheck.test(location.href)) {
            url = encodeURIComponent(location.href.replace(/\#$/g, ''));
        }else {
            url = encodeURIComponent(location.href);
        }

        if($(this).closest('.modal').length>0 && $(this).parent().find('.article-link').length>0) {
            // help library in modal (ex. symptoms)
            url = $(this).parent().find('.article-link').attr('data-url');
        }

        var mailto = 'mailto:?subject=' + title + '&body=' + url;
        location.href = mailto;
    });
    shareLink.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'link');
        //var ClipboardCopy = location.href;
        //url = $(this).data('url');
        url = this.dataset.copyUrl;
        Clipboard.copy(url);
        shareModal.find('.modal-url').text(url);
    });
    shareSms.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'sms');
    });
    shareFB.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'facebook');
        url = $(this).data('url');
        sendShareFb(url);
    });
    shareTW.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'twitter');
        url = $(this).data('url');
        title = $(this).data('title');
        via = $(this).data('via');

        // converting short Url script
        var shortUrl = e.currentTarget.getAttribute("data-short-url");
        if(shortUrl && shortUrl != null) {
            sendShareTw(shortUrl, title, via);
        }else {
            var ajaxData = $(this).closest(".sns-share").data();
            if(ajaxData.paramName) {
                var shortUrlParam = ajaxData.paramName+"="+url;
                var ajaxUrl = 'https://www.lg.com/common/shorturl/getShortUrl.lgajax';
                if(ajaxData.getUrl) {
                    ajaxUrl = ajaxData.getUrl;
                }
                $.ajax({
                    type: "GET",
                    timeout: 5e4,
                    url: ajaxUrl,
                    data: shortUrlParam,
                    dataType: "jsonp",
                    jsonp: "callback",
                    success: $.proxy(function(data) {
                        sendShareTw(data.shortUrl, title, via);
                        //console.log(data);
                    }, this)
                });
            }else {
                sendShareTw(url, title, via);
            }
        }
    });
    sharePI.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'pinterest');
        url = $(this).data('url');
        title = $(this).data('title');
        image = $(this).data('image');
        sendSharePi(url, title, image);
    });
    shareVK.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'vk');
        url = $(this).data('url');
        title = $(this).data('title');
        sendShareVk(url, title);
    });
    shareOK.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'ok');
        url = $(this).data('url');
        sendShareOk(url);
    });
    shareLI.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'linkedin');
        url = $(this).data('url');
        sendShareLi(url);
    });
    shareObj.find('.share-linkdin').off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'linkedin');
        url = $(this).data('url');
        sendShareLi(url);
    });
    shareWB.off('click').on('click', function (e) {
        e.preventDefault();
        adobeShare($(this), 'weibo');
        url = $(this).data('url');
        title = $(this).data('title');
        image = $(this).data('image');
        sendShareWb(url, title, image);
    });
    var nIntervId;
    shareWE.off('click').on('click', function (e) {
        // Only CN
        e.preventDefault();
        adobeShare($(this), 'wechat');

        if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_SOCIAL_MEDIA')) {
            url = $(this).data('url');
            text = $(this).data('text');
            $('body').trigger('ajaxLoadBefore');

            // 20200429 START 박지영 - JSLint 수정
            var loadDOM = function() {
                if ($('#bdshare_weixin_qrcode_dialog').length > 0) {
                    clearInterval(nIntervId);
                    newWindow(true);
                }
            };
            var newWindow = function(isFirst) {
                $('#bdshare_weixin_qrcode_dialog').find('.bd_weixin_popup_close').hide();
                if(isFirst) {
                    $('#bdshare_weixin_qrcode_dialog').css({ 
                        position : 'static',
                        left : 0,
                        top : 0,
                        width: 'auto',
                        height: 'auto'
                    }).wrapAll('<div />');
                }
                var myWindow, htmlTag = '';
                if ('ontouchstart' in window) {
                    myWindow = window.open('', '_blank');
                    htmlTag += '<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=no, minimal-ui">';
                } else {
                    var popupX = (window.screen.width/2) - (300 / 2);
                    var popupY= (window.screen.height/2) - (330 / 2);
                    myWindow = window.open('', 'wechat', 'directories=no, menubar=no, status=no, location=no, toolbar=no, width=300, height=330, left='+popupX+', top='+popupY);
                }
                myWindow.document.getElementsByTagName('html')[0].innerHTML = '';

                htmlTag += '<title>Wechat</title>';
                htmlTag += '<link rel="stylesheet" type="text/css" href="http://bdimg.share.baidu.com/static/api/css/weixin_popup.css"></head>';
                htmlTag += $('#bdshare_weixin_qrcode_dialog').parent('div').html();

                myWindow.document.getElementsByTagName('html')[0].innerHTML = htmlTag;

                setTimeout(function() {
                    $('#bdshare_weixin_qrcode_dialog').addClass('hide');
                    $('body').trigger('ajaxLoadEnd');
                }, 300);
            };
            // 20200429 END
            if ($('#bdshare_weixin_qrcode_dialog').length == 0 && !nIntervId) {
                if(typeof window._bd_share_main == 'object') {
                    nIntervId = setInterval(loadDOM, 1000);
                } else {
                    console.log('Error : Failed to load share.js.');
                    $('body').trigger('ajaxLoadEnd');
                }
            } else {
                newWindow(false);
            }
        } else {
            ePrivacyCookies.view('click');
        }
    });

    function openSns(url) {
        // 20200309 START 박지영 - sns share 레이어 띄울때 쿠키를 사용하지 않으므로 아래 내용 변경
        //if(typeof ePrivacyCookies=='undefined' || ePrivacyCookies.get('LGCOM_SOCIAL_MEDIA')) {
            // Cookie 사용 가능 시 실행할 스크립트
            var winObj;
            var popupX = (window.screen.width/2) - (600 / 2);
            var popupY= (window.screen.height/2) - (800 / 2);
            winObj = window.open(url, 'New Window', 'width=600, height=800,scrollbars=yes, left='+popupX+', top='+popupY);
            // 20200427 박지영 - SNS 팝업 띄우고 나면 레이어 닫기 
            // if($('.sns-area .list').length>0) $('.sns-area .list').removeClass('active'); //PJTPLP-10 SNS툴팁 유지
            // 20200427 END
        //} else {
            // Cookie 사용 불가
            // - click : 고정값
        //  ePrivacyCookies.view('click');
        //}
        // 20200309 END
    }

    function sendShareFb(url) {
        var shareurl = (url) ? url : document.location.href;
        url = "http://www.facebook.com/sharer/sharer.php?u=" + shareurl;
        openSns(url);
    }

    function sendShareTw(url, title, via) {
        var shareurl = (url) ? url : document.location.href;
        var text = (title) ? title : $("head title").text();
        var via2 = (via) ? via : 'LGUS';
        url = "https://twitter.com/intent/tweet?url=" + shareurl + "&text=" + encodeURIComponent(text) + "&via=" + via2;
        openSns(url);
    }

    function sendSharePi(url, title, image) {
        var shareurl = (url) ? url : document.location.href;
        var text = (title) ? title : $("head title").text();
        var img = (image) ? image : $("meta[property='og:image']").attr('content');
        url = "http://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(shareurl) + "&media=" + encodeURIComponent(img) + "&description=" + encodeURIComponent(text);
        openSns(url);
    }

    function sendShareVk(url, title) {
        var shareurl = (url) ? url : document.location.href;
        var text = (title) ? title : $("head title").text();
        url = "https://share.yandex.net/go.xml?service=vkontakte&url=" + shareurl + "&title=" + encodeURIComponent(text);
        openSns(url);
    }

    function sendShareOk(url) {
        var shareurl = (url) ? url : document.location.href;
        url = "https://connect.ok.ru/dk?st.cmd=WidgetSharePreview&st.shareUrl=" + shareurl + '&feature=share';
        openSns(url);
    }

    function sendShareLi(url) {
        var shareurl = (url) ? url : document.location.href;
        url = "https://www.linkedin.com/shareArticle?url=" + shareurl;
        openSns(url);
    }

    function sendShareWb(url, title, image) {
        var shareurl = (url) ? url : document.location.href;
        var text = (title) ? title : $("head title").text();
        var img = (image) ? image : $("meta[property='og:image']").attr('content');
        url = 'http://service.weibo.com/share/share.php?title=' + text + '&url=' + shareurl + '&pic=' + img;
        openSns(url);
    }
    window.Clipboard = (function (window, document, navigator) {
        var textArea,
            copy;

        function isOS() {
            return navigator.userAgent.match(/ipad|iphone/i);
        }

        function createTextArea(text) {
            textArea = document.createElement('textArea');
            textArea.value = text;
            textArea.style.position = 'fixed';
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.opacity = '0.0001';
            textArea.style.width = '100%';
            textArea.style.height = '100%';
            textArea.style.padding = '0';
            textArea.style.pointerEvents = "none";
            textArea.style.fontSize = '16px';


            document.body.appendChild(textArea);
        }

        function selectText() {
            var range,
                selection;

            if (isOS()) {
                range = document.createRange();
                range.selectNodeContents(textArea);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                textArea.setSelectionRange(0, 999999);
            } else {
                textArea.select();
            }
        }

        function copyToClipboard() {
            document.execCommand('copy');
            document.body.removeChild(textArea);
        }

        copy = function (text) {
            createTextArea(text);
            selectText();
            copyToClipboard();
        };
        return {
            copy: copy
        };
    })(window, document, navigator);

    //PLTPJP-4 개선으로 다르게 이벤트 처리 GPC0007::GPC0026
    if(shareObj.find('.sns-inner ul.sns-share').length>0 && !shareObj.parents('.GPC0132').length > 0) {
        // for layer
        shareObj.find('.sns-inner ul.sns-share li:last-child a').on('blur', function() {
            $(this).closest('.list').removeClass('active');
        });
    }
    //PJTPLP-10 GILS GPC0009 SNS툴팁 유지하기 위해 위 소스 GPC0026 추가
}